import React from 'react';
import { ArrowDown } from 'lucide-react';

const HeroSection = ({ 
  title, 
  subtitle, 
  stats, 
  onExploreClick 
}) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 bg-gradient-to-b from-blue-50/50 to-transparent" />
      <div className="relative pt-20 pb-32 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-bold mb-6 opacity-0 animate-fadeIn">
            {title.split('[').map((part, i) => 
              i === 0 ? part : (
                <span key={i} className="text-blue-600 relative group">
                  {part.replace(']','')}
                  <span className="absolute bottom-0 left-0 w-full h-1 bg-blue-200 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300" />
                </span>
              )
            )}
          </h1>
          <p className="text-xl text-gray-600 mb-12 opacity-0 animate-fadeIn animation-delay-200">
            {subtitle}
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
            {stats}
          </div>
          
          <button 
            onClick={onExploreClick}
            className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-700 transition-colors duration-300"
          >
            Explore More
            <ArrowDown className="w-4 h-4 animate-bounce" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ExternalLink } from 'lucide-react';
import MainLayout from '../../../components/layout/MainLayout';
import LandingTemplate from '../../../components/templates/landing/LandingTemplate';

const JournalistLanding = () => {
  const navigate = useNavigate();

  // Article links configuration
  const articles = {
    mainArticle: {
      title: "The WIRED AI Elections Project",
      url: "https://www.wired.com/story/generative-ai-global-elections/",
    },
    relatedArticles: [
      {
        title: "Election Fraud Conspiracy Theories Are Already Thriving Online",
        url: "https://www.wired.com/story/microsoft-ai-copilot-chatbot-election-conspiracy/",
      },
      {
        title: "US Intel Says Insider Threats Are 'Likely' During the Election",
        url: "https://www.wired.com/story/election-insider-threats-likely-2024/",
      }
    ]
  };

  // Content configuration
  const journalistContent = {
    heroTitle: "Your Partner in [Real-Time Fact Verification]",
    heroSubtitle: (
      <span>
        Inspired by investigations like{' '}
        <a 
          href={articles.mainArticle.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline inline-flex items-center"
        >
          {articles.mainArticle.title}
          <ExternalLink className="h-4 w-4 ml-1" />
        </a>
        , our tools empower your reporting with instant verification capabilities.
      </span>
    ),
    
    stats: [
      { value: "92", description: "of AI-generated political content can be verified in under 30 seconds with our system" },
      { value: "78", description: "of emerging election misinformation patterns detected before widespread circulation" },
      { value: "86", description: "reduction in verification time for cross-border political claims" },
    ],

    journeySteps: [
      { 
        number: "1", 
        title: "Instant Verification", 
        description: (
          <span>
            Detect AI-generated content and deepfakes, as seen in{' '}
            <a 
              href={articles.mainArticle.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline inline-flex items-center"
            >
              recent investigations
              <ExternalLink className="h-3 w-3 ml-1" />
            </a>
          </span>
        )
      },
      { 
        number: "2", 
        title: "Cross-Border Monitoring", 
        description: "Track misinformation patterns across international elections" 
      },
      { 
        number: "3", 
        title: "Community Validation", 
        description: "Access a network of verified fact-checkers and experts" 
      },
    ],

    features: [
      { 
        title: (
          <span className="flex items-center">
            Real-Time Investigation Tools
            <a 
              href={articles.relatedArticles[0].url}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2"
            >
              <ExternalLink className="h-4 w-4 text-blue-600" />
            </a>
          </span>
        ),
        items: [
          "Instant deepfake detection for political content",
          "Cross-reference checking with trusted sources",
          "Multi-language support for global coverage"
        ] 
      },
      { 
        title: (
          <span className="flex items-center">
            Newsroom Integration
            <a 
              href={articles.relatedArticles[1].url}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2"
            >
              <ExternalLink className="h-4 w-4 text-blue-600" />
            </a>
          </span>
        ),
        items: [
          "API access for seamless workflow integration",
          "Browser extension for instant verification",
          "Customizable alert system for topic monitoring"
        ] 
      },
      { 
        title: "Investigation Support",
        items: [
          "Track emerging misinformation patterns",
          "International fact-checking network access",
          "Real-time collaboration tools"
        ] 
      },
    ],

    ctaTitle: "Ready to Transform Your Investigative Reporting?",
    ctaDescription: (
      <span>
        Join journalists covering{' '}
        <a 
          href={articles.mainArticle.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline inline-flex items-center"
        >
          AI's impact on elections
          <ExternalLink className="h-4 w-4 ml-1" />
        </a>
        {' '}using JIT Fact-Checking to protect electoral integrity.
      </span>
    ),
    ctaButtonText: "Request Journalist Access",
  };

  const handleCtaClick = () => {
    console.log("Journalist access request clicked");
    // Add your waitlist/contact form logic here
  };

  return (
    <MainLayout>
      {/* Just-in-Time Fact-Checking Section and Back to Home Button */}
      <div className="flex items-center justify-between max-w-3xl mx-auto mt-4 px-4">
        <button
          onClick={() => navigate('/')}
          className="flex items-center text-gray-700 hover:text-blue-600 transition-colors bg-gray-200 rounded-full px-4 py-2 shadow-md"
        >
          <ArrowLeft className="h-5 w-5 mr-1" />
          <span className="text-sm font-medium">Back to Home</span>
        </button>
        <div className="bg-gradient-to-b from-blue-400 to-blue-500 text-white px-4 py-2 rounded-md shadow-lg">
          <h2 className="text-xl font-semibold text-center">JIT Fact-Checking for Journalists</h2>
        </div>
      </div>

      {/* Main Content */}
      <div className="bg-white rounded-lg shadow-lg p-4 mt-6 mb-4 max-w-3xl mx-auto">
        <div className="text-center mb-4">
          <h1 className="text-2xl font-bold">Empower Your Investigation</h1>
          <p className="text-gray-600">
            Real-time tools for verifying AI-generated content and election misinformation.
          </p>
        </div>
        <LandingTemplate {...journalistContent} onCtaClick={handleCtaClick} />
      </div>
    </MainLayout>
  );
};

export default JournalistLanding;
import React from 'react';
import { ArrowRight } from 'lucide-react';

const CtaButton = ({ text, onClick }) => (
  <button 
    onClick={onClick}
    className="group bg-blue-600 text-white px-8 py-3 rounded-lg font-medium 
               hover:bg-blue-700 transition-colors duration-300 flex items-center gap-2"
  >
    {text}
    <ArrowRight className="w-5 h-5 transform transition-transform duration-300 group-hover:translate-x-1" />
  </button>
);

export default CtaButton;
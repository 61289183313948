import React, { useState } from 'react';
import { Menu, X, ArrowRight } from 'lucide-react';

const Logo = () => (
    <svg className="h-10 w-10" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <circle cx="100" cy="100" r="90" fill="#1d4ed8" />
        <circle cx="100" cy="100" r="85" fill="#2563eb" />
        <path
            d="M100 40
          L140 60
          Q150 90 140 120
          Q120 150 100 160
          Q80 150 60 120
          Q50 90 60 60 Z"
            fill="#fbbf24"
            stroke="#1d4ed8"
            strokeWidth="2"
        />
        <text
            x="100"
            y="115"
            fontFamily="Arial, sans-serif"
            fontWeight="bold"
            fontSize="45"
            fill="#1d4ed8"
            textAnchor="middle"
        >
            TB
        </text>
    </svg>
);

const NavBar = ({ onMissionClick, onContactClick, onJoinClick }) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const NavLink = ({ children, onClick }) => (
        <button
            onClick={() => {
                onClick();
                setIsMobileMenuOpen(false);
            }}
            className="text-white hover:text-yellow-200 transition-colors duration-200
                 font-semibold text-lg tracking-wide relative
                 after:content-[''] after:absolute after:w-0 after:h-0.5
                 after:bg-yellow-200 after:left-0 after:-bottom-1
                 after:transition-all after:duration-300
                 hover:after:w-full"
        >
            {children}
        </button>
    );

    const ActionButton = ({ children, onClick }) => (
        <button
            onClick={onClick}
            className="bg-yellow-400 hover:bg-yellow-500 text-blue-900 px-6 py-2.5 rounded-lg
                 font-bold text-sm tracking-wider uppercase
                 flex items-center gap-2 transform transition-all duration-200
                 shadow-[4px_4px_0px_0px_rgba(0,0,0,0.25)]
                 hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,0.25)]
                 hover:translate-x-[2px] hover:translate-y-[2px]"
        >
            {children}
            <ArrowRight className="h-4 w-4" />
        </button>
    );

    return (
        <nav className="fixed top-0 left-0 right-0 z-50 bg-blue-600 shadow-lg">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                <div className="flex items-center justify-between">
                    {/* Logo and Brand Name */}
                    <div className="flex items-center space-x-3 cursor-pointer">
                        <Logo />
                        <div className="hidden sm:flex flex-col">
              <span className="text-2xl font-bold text-white tracking-tight">
                TrustBridge
              </span>
                            <span className="text-yellow-200 text-sm tracking-wide">
                Building Trust Together
              </span>
                        </div>
                    </div>

                    {/* Desktop Navigation */}
                    <div className="hidden md:flex items-center justify-center space-x-12 flex-1 max-w-xl mx-12">
                        <NavLink onClick={onMissionClick}>Our Mission</NavLink>
                        <NavLink onClick={onContactClick}>Contact</NavLink>
                    </div>

                    {/* Action Buttons */}
                    <div className="hidden md:flex items-center space-x-4">
                        <ActionButton onClick={onJoinClick}>Join Now</ActionButton>
                    </div>

                    {/* Mobile Menu Button */}
                    <button
                        className="md:hidden p-2 text-white"
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    >
                        {isMobileMenuOpen ? (
                            <X className="h-6 w-6" />
                        ) : (
                            <Menu className="h-6 w-6" />
                        )}
                    </button>
                </div>
            </div>

            {/* Mobile Navigation Menu */}
            {isMobileMenuOpen && (
                <div className="md:hidden bg-blue-700">
                    <div className="px-4 pt-4 pb-6 space-y-4">
                        <div className="flex flex-col space-y-4 text-center mb-6">
                            <NavLink onClick={onMissionClick}>Our Mission</NavLink>
                            <NavLink onClick={onContactClick}>Contact</NavLink>
                        </div>
                        <div className="flex flex-col space-y-3">
                            <ActionButton onClick={onJoinClick}>Join Now</ActionButton>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default NavBar;
// src/pages/UserProfile.jsx
import React from 'react';
import { useParams } from 'react-router-dom';

const UserProfile = () => {
  const { username } = useParams();
  const shareableLink = `${window.location.origin}/user/${username}`;

  return (
    <div className="max-w-xl mx-auto my-10 p-6 shadow-md border rounded-lg text-center">
      <h2 className="text-2xl font-bold mb-4">Welcome to Your Profile, {username}</h2>
      <p className="text-gray-700 mb-4">Here’s your unique, shareable profile link:</p>
      <a
        href={shareableLink}
        className="text-blue-600 font-medium hover:underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        {shareableLink}
      </a>

      {/* Placeholder for QR Code */}
      <div className="mt-8">
        <p className="text-gray-500">QR Code (coming soon):</p>
        <div className="border p-4 mt-2 inline-block">
          [QR Code Placeholder]
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

import React from 'react';
import HomePage from '../pages/HomePage';
import ContactSection from '../components/contact/ContactSection';
import VoterLanding from '../pages/landing-pages/voter/VoterLanding';
import JobSeekerLanding from '../pages/landing-pages/job-seeker/JobSeekerLanding';
import DemocracyRepublicLanding from '../pages/landing-pages/democracy-republic/DemocracyRepublicLanding';
import JournalistsLanding from '../pages/landing-pages/Journalists/JournalistsLanding';
import PoliticianLanding from '../pages/landing-pages/politician/PoliticianLanding';
import MainLayout from '../components/layout/MainLayout';

const routes = [
  {
    path: '/',
    element: (
      <MainLayout>
        <HomePage />
      </MainLayout>
    ),
  },
  {
    path: '/contact',
    element: (
      <MainLayout>
        <div className="pt-20 min-h-screen bg-gray-50">
          <ContactSection />
        </div>
      </MainLayout>
    ),
  },
  {
    path: '/showcase',
    element: (
      <MainLayout>
        <div className="pt-20 flex min-h-screen flex-col">
          <main className="flex-1">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-8">
              <div className="rounded-lg shadow-lg overflow-hidden">
                <VoterLanding />
              </div>
              <div className="rounded-lg shadow-lg overflow-hidden">
                <JobSeekerLanding />
              </div>
              <div className="rounded-lg shadow-lg overflow-hidden">
                <DemocracyRepublicLanding />
              </div>
              <div className="rounded-lg shadow-lg overflow-hidden">
                <JournalistsLanding />
              </div>
              {/* Adding Politician landing to showcase */}
              <div className="rounded-lg shadow-lg overflow-hidden">
                <PoliticianLanding />
              </div>
            </div>
          </main>
        </div>
      </MainLayout>
    ),
  },
  {
    path: '/voter',
    element: (
      <MainLayout>
        <div className="pt-20">
          <VoterLanding />
        </div>
      </MainLayout>
    ),
  },
  {
    path: '/job-seeker',
    element: (
      <MainLayout>
        <div className="pt-20">
          <JobSeekerLanding />
        </div>
      </MainLayout>
    ),
  },
  {
    path: '/landing/democracy-republic',
    element: (
      <MainLayout>
        <div className="pt-20">
          <DemocracyRepublicLanding />
        </div>
      </MainLayout>
    ),
  },
  {
    path: '/landing/journalists',
    element: (
      <MainLayout>
        <div className="pt-20">
          <JournalistsLanding />
        </div>
      </MainLayout>
    ),
  },
  // New route for Politicians landing page
  {
    path: '/landing/politicians',
    element: (
      <MainLayout>
        <div className="pt-20">
          <PoliticianLanding />
        </div>
      </MainLayout>
    ),
  }
];

export default routes;
import React, { useState, useEffect } from 'react';
import { Clock, Shield, Info } from 'lucide-react';
import HeroSection from './components/HeroSection';
import FeatureCard from './components/FeatureCard';
import StatCard from './components/StatCard';
import JourneyStep from './components/JourneyStep';
import CtaButton from './components/CtaButton';
import StatusBanner from './components/StatusBanner';
import './styles/animations.css';

const LandingTemplate = ({ 
  heroTitle,
  heroSubtitle,
  stats,
  journeySteps,
  features,
  ctaTitle,
  ctaDescription,
  ctaButtonText,
  onCtaClick
}) => {
  const [activeSection, setActiveSection] = useState('hero');
  const [showBanner, setShowBanner] = useState(false);
  const featureIcons = [Clock, Shield, Info];

  // Intersection Observer for section activation
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    document.querySelectorAll('section[id]').forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  const handleExploreClick = () => {
    document.getElementById('journey').scrollIntoView({ behavior: 'smooth' });
  };

  const handleCtaClick = () => {
    setShowBanner(true);
    onCtaClick?.();
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50">
      {showBanner && (
        <StatusBanner 
          type="success"
          message="Thanks for your interest! We'll be in touch soon."
          onClose={() => setShowBanner(false)}
        />
      )}

      <HeroSection
        title={heroTitle}
        subtitle={heroSubtitle}
        stats={stats.map((stat, index) => (
          <StatCard key={index} {...stat} animationDelay={index * 200} />
        ))}
        onExploreClick={handleExploreClick}
      />

      <section id="journey" className="py-16 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-16">Your Path Forward</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 stagger-fade-in">
            {journeySteps.map((step, index) => (
              <JourneyStep key={index} {...step} animationDelay={index * 200} />
            ))}
          </div>
        </div>
      </section>

      <section id="features" className="py-16 px-4 bg-white">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-16">Why People Trust Us</h2>
          <div className="space-y-8">
            {features.map((feature, index) => (
              <FeatureCard 
                key={index} 
                {...feature} 
                icon={featureIcons[index]} 
                animationDelay={index * 200}
              />
            ))}
          </div>
        </div>
      </section>

      <section id="cta" className="py-20 px-4 bg-gradient-to-b from-blue-50 to-white">
        <div className="max-w-xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">{ctaTitle}</h2>
          <p className="text-gray-600 mb-8">{ctaDescription}</p>
          <CtaButton text={ctaButtonText} onClick={handleCtaClick} />
        </div>
      </section>

      <div className="fixed bottom-4 right-4 z-50">
        <div className="bg-white rounded-lg shadow-lg p-2">
          <nav className="flex flex-col gap-2">
            {['hero', 'journey', 'features', 'cta'].map((section) => (
              <button
                key={section}
                className={`w-3 h-3 rounded-full transition-colors duration-300 ${
                  activeSection === section ? 'bg-blue-600' : 'bg-gray-300'
                }`}
                onClick={() => document.getElementById(section).scrollIntoView({ behavior: 'smooth' })}
              />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default LandingTemplate;
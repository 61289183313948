import React from 'react';
import { Clock, ShieldCheck, Zap } from 'lucide-react';
import OIP from '../../../public/images/OIP.jpeg';

// Custom Benefit Icon component with background blob
const BenefitIcon = ({ Icon, bgColor }) => (
    <div className="relative w-20 h-20"> {/* Increased size */}
        <svg className="w-20 h-20" viewBox="0 0 100 100">
            <circle
                cx="50"
                cy="50"
                r="50"
                fill={bgColor}
                className="animate-pulse"
            />
        </svg>
    {/*<div className="relative">*/}
    {/*    <svg className="w-16 h-50" viewBox="0 0 100 100">*/}
    {/*        <path*/}
    {/*            d="M50,20*/}
    {/*       C70,20 85,35 85,50*/}
    {/*       C85,65 70,80 50,80*/}
    {/*       C30,80 15,65 15,50*/}
    {/*       C15,35 30,20 50,20*/}
    {/*       Z"*/}
    {/*            fill={bgColor}*/}
    {/*            className="animate-pulse"*/}
    {/*        />*/}
    {/*    </svg>*/}
        <div className="absolute inset-0 flex items-center justify-center">
            <Icon className="w-10 h-8 text-blue-700" />
        </div>
    </div>
);

const HeroSection = () => {
    return (
        <div className="min-h-screen pt-16"> {/* pt-16 accounts for navbar height */}
            <div className="flex flex-col lg:flex-row">
                {/* Left Section with Blue Background */}
                <div className="lg:w-[70%] bg-blue-600 min-h-[600px] px-8 lg:px-16 py-20 lg:py-32
                      flex flex-col justify-center relative overflow-hidden">
                    {/* Background Pattern */}
                    <div className="absolute inset-0 opacity-10">
                        <svg width="100%" height="100%">
                            <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
                                <path d="M 40 0 L 0 0 0 40" fill="none" stroke="white" strokeWidth="1"/>
                            </pattern>
                            <rect width="100%" height="100%" fill="url(#grid)" />
                        </svg>
                    </div>

                    {/* Main Content */}
                    <div className="relative z-10">
                        <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-white leading-tight mb-6">
                            A Misinformed Decision Can Alter the Course of Your Life
                        </h1>
                        <p className="text-xl lg:text-2xl text-yellow-200 font-medium mb-12">
                            Don't let that happen. Make informed decisions with our
                            <span className="font-bold"> JUST IN TIME </span>
                            verification software.
                        </p>

                        {/* Benefits Section */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
                            {/* Instant Verification */}
                            <div className="flex flex-col items-center text-center text-white">
                                <BenefitIcon Icon={Zap} bgColor="#fef3c7" />
                                <h3 className="text-xl font-bold mt-4 mb-2">Lightning Fast</h3>
                                <p className="text-yellow-200">
                                    Get instant fact verification within seconds of your request
                                </p>
                            </div>

                            {/* Real-time Accuracy */}
                            <div className="flex flex-col items-center text-center text-white">
                                <BenefitIcon Icon={Clock} bgColor="#fef3c7" />
                                <h3 className="text-xl font-bold mt-4 mb-2">Real-Time Updates</h3>
                                <p className="text-yellow-200">
                                    Access the latest information for time-critical decisions
                                </p>
                            </div>

                            {/* Trusted Results */}
                            <div className="flex flex-col items-center text-center text-white">
                                <BenefitIcon Icon={ShieldCheck} bgColor="#fef3c7" />
                                <h3 className="text-xl font-bold mt-4 mb-2">Trusted Sources</h3>
                                <p className="text-yellow-200">
                                    Verified information from reliable and authenticated sources
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right Section with Image */}
                <div className="lg:w-[30%] bg-gray-100 min-h-[400px] lg:min-h-[600px]
                      relative overflow-hidden">
                    <img
                        src={OIP}
                        alt="Person showing regret"
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                    {/* Overlay to enhance text contrast */}
                    <div className="absolute inset-0 bg-black opacity-20"></div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
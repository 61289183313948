import React from 'react';

const ScrollingBanner = () => {
    const handleClick = () => {
        // Replace with your actual waitlist path
        window.location.href = '/waitlist';
    };

    const scrollText = "🔥 EARLY BIRD ACCESS AVAILABLE NOW! 🚀 ";
    const repeatedText = scrollText.repeat(8); // Reduced repeats since text is larger

    return (
        <div
            onClick={handleClick}
            className="bg-yellow-400 overflow-hidden py-4 relative cursor-pointer
                transition-all duration-300 hover:shadow-lg hover:bg-yellow-300"
        >
            {/* Shadow overlays for fade effect */}
            <div className="absolute left-0 top-0 bottom-0 w-20 bg-gradient-to-r from-yellow-400 to-transparent z-10
                    group-hover:from-yellow-300"></div>
            <div className="absolute right-0 top-0 bottom-0 w-20 bg-gradient-to-l from-yellow-400 to-transparent z-10
                    group-hover:from-yellow-300"></div>

            {/* Single line of scrolling content */}
            <div className="whitespace-nowrap inline-block animate-scroll">
        <span className="text-blue-900 font-black text-4xl tracking-wider uppercase">
          {repeatedText}
        </span>
            </div>

        {/*    /!* Duplicate for seamless loop *!/*/}
        {/*    <div className="whitespace-nowrap inline-block animate-scroll">*/}
        {/*<span className="text-blue-900 font-black text-4xl tracking-wider uppercase">*/}
        {/*  {repeatedText}*/}
        {/*</span>*/}
        {/*    </div>*/}

            <style jsx>{`
              @keyframes scroll {
                0% {
                  transform: translateX(0);
                }
                100% {
                  transform: translateX(-100%);
                }
              }

              .animate-scroll {
                animation: scroll 60s linear infinite; /* Doubled the duration to 60s for slower scroll */
              }

              /* Optional: pause animation on hover */
              .animate-scroll:hover {
                animation-play-state: paused;
              }
            `}</style>
        </div>
    );
};

export default ScrollingBanner;
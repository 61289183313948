import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import MainLayout from '../../../components/layout/MainLayout';
import LandingTemplate from '../../../components/templates/landing/LandingTemplate';

const PoliticianLanding = () => {
  const navigate = useNavigate();

  // Content configuration
  const politicianContent = {
    heroTitle: "Champion Truth, Serve Your Community",
    heroSubtitle:
      "Empower your public service with real-time fact-checking to bring transparency, accuracy, and trust into every speech, debate, and discussion.",
    stats: [
      { value: "82", description: "of constituents support leaders who fact-check in real-time" },
      { value: "91", description: "of claims checked in public debates enhance audience trust" },
      { value: "95", description: "user satisfaction with fact-checking accuracy during live events" },
    ],
    journeySteps: [
      { number: "1", title: "Build Trust Instantly", description: "Gain credibility with real-time fact-checking during speeches and debates" },
      { number: "2", title: "Empower Informed Communities", description: "Share clear, verified facts that bring context to critical issues" },
      { number: "3", title: "Engage with Confidence", description: "Promote truth, transparency, and integrity in every interaction" },
    ],
    features: [
      { title: "Lead with Transparency", items: ["Real-time fact-checking available during public appearances"] },
      { title: "Strengthen Public Trust", items: ["Verified facts backed by reliable sources"] },
      { title: "Stay Aligned with Community Values", items: ["Instant updates and explanations for complex topics"] },
    ],
    ctaTitle: "Ready to Transform Your Public Service?",
    ctaDescription: "Join fellow leaders bringing transparency to public office with real-time fact-checking.",
    ctaButtonText: "Join the Waitlist",
  };

  const handleCtaClick = () => {
    console.log("Politician waitlist signup clicked");
  };

  return (
    <MainLayout>
      {/* Just-in-Time Fact-Checking Section and Back to Home Button */}
      <div className="flex items-center justify-between max-w-3xl mx-auto mt-4 px-4">
        <button
          onClick={() => navigate('/')}
          className="flex items-center text-gray-700 hover:text-blue-600 transition-colors bg-gray-200 rounded-full px-4 py-2 shadow-md"
        >
          <ArrowLeft className="h-5 w-5 mr-1" />
          <span className="text-sm font-medium">Back to Home</span>
        </button>
        <div className="bg-gradient-to-b from-yellow-400 to-yellow-500 text-black px-4 py-2 rounded-md shadow-lg">
          <h2 className="text-xl font-semibold text-center">Just-in-Time Fact-Checking for Leaders</h2>
        </div>
      </div>

      {/* Main Content */}
      <div className="bg-white rounded-lg shadow-lg p-4 mt-6 mb-4 max-w-3xl mx-auto">
        <div className="text-center mb-4">
          <h1 className="text-2xl font-bold">Serve with Integrity</h1>
          <p className="text-gray-600">Enhance transparency, accuracy, and public trust in every message.</p>
        </div>
        <LandingTemplate {...politicianContent} onCtaClick={handleCtaClick} />
      </div>
    </MainLayout>
  );
};

export default PoliticianLanding;

import React, { useState } from 'react';
import { X, Bell } from 'lucide-react';

const StatusBanner = ({ type = 'info', message, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    onClose?.();
  };

  if (!isVisible) return null;

  const colors = {
    info: 'bg-blue-50 text-blue-800',
    success: 'bg-green-50 text-green-800',
    warning: 'bg-yellow-50 text-yellow-800'
  };

  return (
    <div className={`fixed top-0 left-0 right-0 z-50 ${colors[type]} transition-all duration-300`}>
      <div className="max-w-7xl mx-auto px-4 py-3 flex items-center justify-between">
        <div className="flex items-center gap-3">
          <Bell className="w-5 h-5" />
          <p className="text-sm font-medium">{message}</p>
        </div>
        <button 
          onClick={handleClose}
          className="p-1 hover:opacity-75 transition-opacity"
        >
          <X className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default StatusBanner;
import React, { useState, useEffect } from 'react';
import { Clock3, CheckCircle } from 'lucide-react';

const JourneyStep = ({ number, title, description, details, animationDelay = 0 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), animationDelay);
    return () => clearTimeout(timer);
  }, [animationDelay]);
  
  return (
    <div 
      className={`transform transition-all duration-500 ${
        isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
      }`}
    >
      <div 
        className="journey-step bg-white rounded-xl p-8 cursor-pointer transition-all duration-300 hover:shadow-lg"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="text-3xl font-bold text-blue-600 mb-4 flex items-center gap-2">
          <span>{number}</span>
          <Clock3 className="w-6 h-6" />
        </div>
        <h3 className="text-xl font-bold mb-4">{title}</h3>
        <p className="text-gray-600">{description}</p>
        
        {details && (
          <div className={`
            pt-4 border-t mt-4 transition-all duration-300
            ${isExpanded ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}
          `}>
            <ul className="space-y-3">
              {details.map((detail, index) => (
                <li 
                  key={index} 
                  className="flex items-start gap-2 transform transition-all duration-300"
                  style={{ transitionDelay: `${index * 100}ms` }}
                >
                  <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0 mt-1" />
                  <span>{detail}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default JourneyStep;
import React, { useState, useEffect } from 'react';
import { ChevronDown, CheckCircle } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, items, animationDelay = 0 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), animationDelay);
    return () => clearTimeout(timer);
  }, [animationDelay]);
  
  return (
    <div 
      className={`transform transition-all duration-500 ${
        isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
      }`}
    >
      <div 
        className="bg-blue-50 rounded-xl p-6 cursor-pointer transition-all duration-300 hover:shadow-lg"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <Icon className="w-8 h-8 text-blue-600" />
            <h3 className="text-xl font-bold">{title}</h3>
          </div>
          <ChevronDown 
            className={`w-5 h-5 text-blue-600 transition-transform duration-300 ${
              isExpanded ? 'rotate-180' : ''
            }`}
          />
        </div>
        
        <div className={`
          mt-4 pl-12 transition-all duration-300 
          ${isExpanded ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}
        `}>
          <ul className="space-y-3">
            {items.map((item, index) => (
              <li 
                key={index} 
                className="flex items-start gap-2 transform transition-all duration-300"
                style={{ transitionDelay: `${index * 100}ms` }}
              >
                <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0 mt-1" />
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
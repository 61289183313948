import React, { useState, useEffect } from 'react';
import AnimatedCounter from './AnimatedCounter';

const StatCard = ({ value, description, animationDelay = 0 }) => {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), animationDelay);
    return () => clearTimeout(timer);
  }, [animationDelay]);
  
  return (
    <div 
      className={`transform transition-all duration-500 ${
        isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
      }`}
    >
      <div className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <div className="text-4xl font-bold text-blue-600 mb-2">
          <AnimatedCounter value={value} />
        </div>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default StatCard;
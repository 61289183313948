import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import UserProfile from './pages/UserProfile';
import LandingPageShowcase from './pages/showcase/LandingPageShowcase';
import VoterLanding from './pages/landing-pages/voter/VoterLanding';
import JobSeekerLanding from './pages/landing-pages/job-seeker/JobSeekerLanding';
import routes from './routes';

const App = () => {
  // Preserve existing manual routing function
  const path = window.location.pathname;
  const getComponent = () => {
    switch (path) {
      case '/':
        return <HomePage />;
      case '/showcase':
        return <LandingPageShowcase />;
      case '/voter':
        return <VoterLanding />;
      case '/job-seeker':
        return <JobSeekerLanding />;
      default:
        return <HomePage />;
    }
  };

  return (
    <Router>
      <Routes>
        {/* Keep existing dynamic user route */}
        <Route path="/user/:username" element={<UserProfile />} />
        
        {/* Add new routes from routes configuration */}
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={route.element}
          />
        ))}

        {/* Preserve fallback route with manual routing */}
        <Route 
          path="*" 
          element={
            <div className="app-container">
              {getComponent()}
            </div>
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import MainLayout from '../../../components/layout/MainLayout';
import LandingTemplate from '../../../components/templates/landing/LandingTemplate';

const DemocracyRepublicLanding = () => {
 const navigate = useNavigate();
 
 const democracyContent = {
   heroTitle: "Understanding America's Democratic Foundation",
   heroSubtitle: "Navigate the complex interplay between democratic and republican principles in American governance with real-time fact-checking of historical claims and founding documents.",
   stats: [
     { value: "82", description: "of constitutional discussions about democratic principles require historical verification to be fully understood" },
     { value: "91", description: "of claims about founding documents need additional context to avoid misinterpretation" },
     { value: "95", description: "accuracy rate in verifying historical claims through peer-reviewed constitutional sources" }
   ],
   journeySteps: [
     { 
       number: "1", 
       title: "Verify Historical Claims",
       description: "Access instant fact-checking of historical references, constitutional interpretations, and founding documents with comprehensive sourcing from the Constitutional Convention"
     },
     {
       number: "2", 
       title: "Track Democratic Evolution",
       description: "Follow the transformation from original republic through constitutional amendments, landmark Supreme Court decisions, and evolving interpretations of democratic principles"
     },
     {
       number: "3", 
       title: "Apply to Modern Debates",
       description: "Connect founding principles to current discussions about democracy, representation, and governance with verified historical context"
     }
   ],
   features: [
     { 
       title: "Constitutional Verification", 
       items: [
         "Real-time verification against original founding documents",
         "Cross-reference with Supreme Court precedents",
         "Historical context from Constitutional Convention debates",
         "Analysis of Federalist Papers and key historical writings"
       ]
     },
     {
       title: "Democratic Evolution Tracking",
       items: [
         "Comprehensive timeline of democratic reforms",
         "Impact analysis of constitutional amendments",
         "Documentation of voting rights expansion",
         "Evolution of representation principles"
       ]
     },
     {
       title: "Modern Application",
       items: [
         "Link historical principles to current debates",
         "Real-time fact-checking of interpretations",
         "Verification of founding fathers' intents",
         "Historical precedent analysis"
       ]
     }
   ],
   ctaTitle: "Ready to Explore America's Democratic Foundations?",
   ctaDescription: "Join others using real-time fact-checking to understand and accurately interpret our system of government.",
   ctaButtonText: "Join the Waitlist",
 };

 const handleCtaClick = () => {
   console.log("Democracy Republic waitlist signup clicked");
 };

 return (
   <MainLayout>
     <div className="flex items-center justify-between max-w-3xl mx-auto mt-4 px-4">
       <button
         onClick={() => navigate('/')}
         className="flex items-center text-gray-700 hover:text-blue-600 transition-colors bg-gray-200 rounded-full px-4 py-2 shadow-md"
       >
         <ArrowLeft className="h-5 w-5 mr-1" />
         <span className="text-sm font-medium">Back to Home</span>
       </button>
       <div className="bg-gradient-to-b from-yellow-400 to-yellow-500 text-black px-4 py-2 rounded-md shadow-lg">
         <h2 className="text-xl font-semibold text-center">JIT Fact-Checking for Democracy</h2>
       </div>
     </div>
     <div className="bg-white rounded-lg shadow-lg p-4 mt-6 mb-4 max-w-3xl mx-auto">
       <div className="text-center mb-4">
         <h1 className="text-2xl font-bold">Understand Our Democratic Republic</h1>
         <p className="text-gray-600">Accurate historical data to illuminate America's governing principles.</p>
       </div>
       <LandingTemplate {...democracyContent} onCtaClick={handleCtaClick} />
     </div>
   </MainLayout>
 );
};

export default DemocracyRepublicLanding;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import MainLayout from '../../../components/layout/MainLayout';
import LandingTemplate from '../../../components/templates/landing/LandingTemplate';

const JobSeekerLanding = () => {
  const navigate = useNavigate();
  // Content configuration
  const jobSeekerContent = {
    heroTitle: "Find Your [True Fit] Beyond the Promotion",
    heroSubtitle:
      "Cut through company marketing to discover real workplace insights with AI-powered fact-checking and employee verification.",
    stats: [
      { value: "89", description: "of job seekers make better decisions with verified insights" },
      { value: "65", description: "of company claims need context or verification" },
      { value: "350", description: "faster research with our AI-powered insights" },
    ],
    journeySteps: [
      {
        number: "1",
        title: "Verify Claims",
        description: "Get real data behind company statements",
        details: ["Compare benefits", "Verify growth opportunities", "Understand company culture"],
      },
      {
        number: "2",
        title: "Research Efficiently",
        description: "Access organized, verified company information",
        details: ["Comprehensive profiles", "Employee experiences", "Salary and benefits data"],
      },
      {
        number: "3",
        title: "Make Confident Decisions",
        description: "Choose your next role with confidence",
        details: ["Compare opportunities", "Understand the full picture", "Avoid pitfalls"],
      },
    ],
    features: [
      {
        title: "Save Time on Research",
        items: ["AI-powered company analysis", "Instant benefit comparisons", "Employee insights"],
      },
      {
        title: "Make Informed Decisions",
        items: ["Verified data", "Culture insights", "Growth trajectory analysis"],
      },
      {
        title: "Stay Ahead of Changes",
        items: ["Real-time updates", "Industry trends", "Career alerts"],
      },
    ],
    ctaTitle: "Ready to Find Your True Fit?",
    ctaDescription: "Join thousands making confident career decisions with verified insights.",
    ctaButtonText: "Get Early Access",
  };

  const handleCtaClick = () => {
    console.log("Job seeker early access signup clicked");
  };

  return (
    <MainLayout>
      {/* Just-in-Time Fact-Checking Section and Back to Home Button */}
      <div className="flex items-center justify-between max-w-3xl mx-auto mt-4 px-4">
        <button
          onClick={() => navigate('/')}
          className="flex items-center text-gray-700 hover:text-blue-600 transition-colors bg-gray-200 rounded-full px-4 py-2 shadow-md"
        >
          <ArrowLeft className="h-5 w-5 mr-1" />
          <span className="text-sm font-medium">Back to Home</span>
        </button>
        <div className="bg-gradient-to-b from-yellow-400 to-yellow-500 text-black px-4 py-2 rounded-md shadow-lg">
          <h2 className="text-xl font-semibold text-center">JIT Fact-Checking for Job Seekers</h2>
        </div>
      </div>
      {/* Main Content - Adjusted margin and padding */}
      <div className="bg-white rounded-lg shadow-lg p-4 mt-4 max-w-3xl mx-auto">
        <div className="text-center mb-4">
          <h1 className="text-2xl font-bold">Empower Your Job Search</h1>
          <p className="text-gray-600">Reliable information to help you make informed decisions.</p>
        </div>
        <div className="-mt-4"> {/* This negative margin helps reduce the white space */}
          <LandingTemplate {...jobSeekerContent} onCtaClick={handleCtaClick} />
        </div>
      </div>
    </MainLayout>
  );
};

export default JobSeekerLanding;
import React, { useRef } from 'react';
import { Shield, Check, Users, Globe } from 'lucide-react';
import MainNav from '../components/navigation/MainNav';
import HeroSection from "components/layout/HeroSection";
import ScrollingBanner from "components/layout/ScrollingBanner.jsx";
import MainLayout from '../components/layout/MainLayout';
import ContactSection from '../components/contact/ContactSection';
import EnhancedForms from '../components/EnhancedForms';

const HomePage = () => {
  // Create refs for each section
  const missionRef = useRef(null);
  const contactRef = useRef(null);
  const joinRef = useRef(null);

  // Scroll handler function
  const scrollToSection = (sectionRef) => {
    sectionRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
      // <MainLayout>
        <div className="min-h-screen bg-white">
          <MainNav
              onMissionClick={() => scrollToSection(missionRef)}
              onContactClick={() => scrollToSection(contactRef)}
              onJoinClick={() => scrollToSection(joinRef)}
          />

          {/* Hero Section */}
          <HeroSection />
          <ScrollingBanner />

          {/* Mission Section */}
          <section ref={missionRef} className="pt-4 bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-6xl text-blue-950 font-bold text-center mb-12">Our Mission</h1>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <Check className="w-8 h-8 text-black-600 mb-4" />
                  <h3 className="text-xl font-semibold mb-3">Instant Verification</h3>
                  <p>Using advanced AI and human expertise, we analyze claims in real-time to provide immediate fact-checking results.</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <Users className="w-8 h-8 text-blue-600 mb-4" />
                  <h3 className="text-xl font-semibold mb-3">Community Collaboration</h3>
                  <p>Expert fact-checkers and community members work together to maintain high accuracy standards.</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <Globe className="w-8 h-8 text-black-600 mb-4" />
                  <h3 className="text-xl font-semibold mb-3">Global Impact</h3>
                  <p>Fighting misinformation across multiple languages and cultures to create a more informed world.</p>
                </div>
              </div>
            </div>
          </section>

          {/* Join Section */}
          <section ref={joinRef} className="pt-4">
            <div className="max-w-7xl mx-auto">
              <EnhancedForms />
            </div>
          </section>

          {/* Contact Section */}
          <section ref={contactRef} className="py-4 bg-gray-50">
            <ContactSection />
          </section>
        </div>
      // </MainLayout>
  );
};

export default HomePage;
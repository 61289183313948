import React from 'react';
import { Layout } from 'lucide-react';
import MainNav from '../navigation/MainNav';

const MainLayout = ({ children }) => {
  const currentPath = window.location.pathname;
  const isHomePage = currentPath === '/';
  
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Main navigation bar */}
      <MainNav currentPath={currentPath} />
      
      <main className="flex-1"> 
        {/* Commented out for now: pt-1 and inner padding container
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-1">
        */}
          {children}
        {/* Commented out for now: closing div for inner padding container
        </div>
        */}
      </main>
      
      <footer className="bg-white border-t mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <p className="text-center text-sm text-gray-500">
            © {new Date().getFullYear()} Just-in-Time Fact-Checking. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default MainLayout;
import React, { useState } from 'react';
import { cn } from '../../lib/utils';
import {
  Link,
  Phone,
  MapPin,
  CreditCard,
  Podcast,
  Linkedin,
  HelpCircle,
  MessageSquare
} from 'lucide-react';

function ContactSection() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('Sending...');

    try {
      const form = e.target;
      const data = new FormData(form);

      fetch("/", {
        method: "POST",
        body: data
      })
          .then(() => {
            setSubmitStatus('Form submitted successfully!');
            setSubmitted(true);
            setFormData({ name: '', email: '', message: '' });
          })
          .catch((error) => {
            console.error('Form submission error:', error);
            setSubmitStatus('Error submitting form. Please try again.');
          });
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmitStatus('Error submitting form. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-4xl font-bold text-[#004D40] mb-12">Contact us</h1>

          <div className="grid md:grid-cols-2 gap-8">
            {/* Contact Information Card */}
            <div className="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex flex-col items-center text-center">
                <div className="w-16 h-16 bg-pink-300 rounded-full flex items-center justify-center mb-6">
                  <HelpCircle className="w-8 h-8 text-white" />
                </div>
                <h2 className="text-2xl font-semibold text-[#004D40] mb-4">
                  Product and account support
                </h2>
                <p className="text-gray-600 mb-6">
                  Our help center is fresh and always open for business. If you can't find the answer you're looking for, we're here to lend a hand.
                </p>

                <div className="space-y-4 w-full text-left mb-8">
                  <div className="flex items-center gap-3">
                    <Link className="h-5 w-5 text-blue-600" />
                    <a href="mailto:info@datamatchhub.com" className="text-blue-600 hover:text-blue-800">
                      info@datamatchhub.com
                    </a>
                  </div>
                  <div className="flex items-center gap-3">
                    <Phone className="h-5 w-5 text-blue-600" />
                    <a href="tel:+31648148284" className="text-blue-600 hover:text-blue-800">
                      +31 648 148 284
                    </a>
                  </div>
                  <div className="flex items-center gap-3">
                    <MapPin className="h-5 w-5 text-blue-600" />
                    <div>
                      <p>Jan van Galenstraat 27 A</p>
                      <p>1051 KE Amsterdam, Netherlands</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <Podcast className="h-5 w-5 text-blue-600" />
                    <a href="https://notebooklm.google.com/notebook/13ed0fd9-c111-4925-bf4e-263dd25a195c/audio"
                       target="_blank"
                       rel="noopener noreferrer"
                       className="text-blue-600 hover:text-blue-800">
                      JIT fact-checking podcast
                    </a>
                  </div>
                  <div className="flex items-center gap-3">
                    <Linkedin className="h-5 w-5 text-blue-600" />
                    <a href="https://www.linkedin.com/in/giovanni-drs-h-b-kerkhof-6871905/"
                       target="_blank"
                       rel="noopener noreferrer"
                       className="text-blue-600 hover:text-blue-800">
                      Giovanni Kerkhof
                    </a>
                  </div>

                  {/* Payment Section with QR Code */}
                  <div className="mt-6 border-t pt-6">
                    <div className="flex items-start gap-3">
                      <CreditCard className="h-5 w-5 text-blue-600 mt-1" />
                      <div>
                        <p className="font-medium mb-2">Payment:</p>
                        <a
                            href="https://www.ing.nl/payreq/m/?trxid=8DCNIujSJaxxhLOyo7LyCjPWo3K8jcRc"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800"
                        >
                          ING payment link
                        </a>

                        <div className="mt-4">
                          <p className="font-medium mb-2">Scan QR Code to donate:</p>
                          <div className="bg-gray-100 rounded-lg p-2 shadow inline-block">
                            <a
                                href="https://www.ing.nl/payreq/m/?trxid=8DCNIujSJaxxhLOyo7LyCjPWo3K8jcRc"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                              <img
                                  src="/images/ing-payment-qr.png"
                                  alt="ING Payment QR Code"
                                  className="w-32 h-32"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Form Card */}
            <div className="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex flex-col items-center text-center mb-8">
                <div className="w-16 h-16 bg-yellow-400 rounded-full flex items-center justify-center mb-6">
                  <MessageSquare className="w-8 h-8 text-white" />
                </div>
                <h2 className="text-2xl font-semibold text-[#004D40] mb-4">
                  Send us a message
                </h2>
                <p className="text-gray-600">
                  We'd love to hear from you. Fill out the form below and we'll get back to you soon.
                </p>
              </div>

              {submitStatus && (
                  <div className={cn(
                      "mb-6 rounded-lg border p-4",
                      submitStatus.includes('successfully') ? "bg-green-50 border-green-200" : "bg-yellow-50 border-yellow-200"
                  )}>
                    <p className={submitStatus.includes('successfully') ? "text-green-700" : "text-yellow-700"}>
                      {submitStatus}
                    </p>
                  </div>
              )}

              <form
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                  className="space-y-6"
              >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don't fill this out if you're human: <input name="bot-field" />
                  </label>
                </p>

                <input
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#004D40] focus:border-transparent"
                    placeholder="Your name"
                />

                <input
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#004D40] focus:border-transparent"
                    placeholder="your@email.com"
                />

                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#004D40] focus:border-transparent min-h-[150px]"
                    placeholder="Your message here..."
                />

                <button
                    type="submit"
                    className="w-full bg-[#004D40] text-white py-3 rounded-lg hover:bg-[#00695C] transition-colors"
                >
                  {submitStatus === 'Sending...' ? 'Sending...' : 'Send Message'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
  );
}

export default ContactSection;
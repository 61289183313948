import React, { useState } from 'react';
import { Shield, X } from 'lucide-react';
import axios from 'axios'; // Make sure to install axios

// API Configuration
const api = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL || 'https://jitfc-depnp5yqd-1fahadshakeels-projects.vercel.app/api',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});


// Form validation helper
const validateForm = (data) => {
    const errors = {};

    if (!data.name || data.name.length < 2) {
        errors.name = 'Name must be at least 2 characters';
    }

    if (!data.email || !/\S+@\S+\.\S+/.test(data.email)) {
        errors.email = 'Please enter a valid email';
    }

    if (!data.state) {
        errors.state = 'Please select a state';
    }

    if (data.password && data.password.length < 6) {
        errors.password = 'Password must be at least 6 characters';
    }

    return errors;
};

const EnhancedForms = () => {
    const [showWaitlist, setShowWaitlist] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [waitlistError, setWaitlistError] = useState('');
    const [signupErrors, setSignupErrors] = useState({
        name: '',
        email: '',
        password: '',
        state: '',
        general: ''
    });

    const usStates = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", /* ... rest of states ... */
    ];

    const handleWaitlistSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setWaitlistError('');

        const formData = new FormData(e.target);
        const data = {
            name: formData.get('name'),
            email: formData.get('email'),
            state: formData.get('state')
        };

        // Validate form
        const validationErrors = validateForm(data);
        if (Object.keys(validationErrors).length > 0) {
            setWaitlistError('Please check your inputs and try again');
            setIsLoading(false);
            return;
        }

        try {
            const response = await api.post('/waitlist/join', data, {
                headers: {
                    'Authorization': 'Bearer e2e1826f6f1b8e66e3993db5f3e06b83bf484089727429222093d975c12ff9b01fd2126e2db4d331e741ad4fdf2269da79866e6c34e36b028506a5253c725fc4'                }
            });

            if (response.data.status === 'success') {
                alert('Successfully joined waitlist!');
                setShowWaitlist(false);
                e.target.reset();
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error('Waitlist Error:', error);
            setWaitlistError(
                error.response?.data?.message ||
                error.message ||
                'Failed to join waitlist. Please try again.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignupSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setSignupErrors({
            name: '',
            email: '',
            password: '',
            state: '',
            general: ''
        });

        const formData = new FormData(e.target);
        const data = {
            name: formData.get('name'),
            email: formData.get('email'),
            password: formData.get('password'),
            state: formData.get('state')
        };

        // Validate form
        const validationErrors = validateForm(data);
        if (Object.keys(validationErrors).length > 0) {
            setSignupErrors({
                ...validationErrors,
                general: 'Please correct the errors above'
            });
            setIsLoading(false);
            return;
        }

        try {
            const response = await api.post('/users/register', data, {
                headers: {
                    'Authorization': 'Bearer e2e1826f6f1b8e66e3993db5f3e06b83bf484089727429222093d975c12ff9b01fd2126e2db4d331e741ad4fdf2269da79866e6c34e36b028506a5253c725fc4'
                }
            });

            if (response.data.status === 'success') {
                if (response.data.token) {
                    localStorage.setItem('token', response.data.token);
                }
                alert('Successfully signed up!');
                setShowSignup(false);
                e.target.reset();
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error('Signup Error:', error);
            if (error.response?.data?.errors) {
                setSignupErrors({
                    ...signupErrors,
                    ...error.response.data.errors,
                    general: error.response.data.message || ''
                });
            } else {
                setSignupErrors({
                    ...signupErrors,
                    general: error.response?.data?.message ||
                        error.message ||
                        'Registration failed. Please try again.'
                });
            }
        } finally {
            setIsLoading(false);
        }
    };
    // Modal Component
    const Modal = ({ children, onClose }) => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                >
                    <X className="w-6 h-6" />
                </button>
                {children}
            </div>
        </div>
    );

    const submitButtonClass = `w-full bg-[#4169E1] text-white px-6 py-3 rounded-lg 
        hover:bg-blue-600 transition-colors
        ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`;
    return (
        <div className="bg-[#4169E1] text-white pb-12 sm:pb-16">
            <div className="max-w-7xl mx-auto py-4 sm:py-6 lg:py-8">
                <div className="text-center">
                    <Shield className="h-16 w-16 sm:h-24 sm:w-24 pt-3 text-[#FFD700] mx-auto mb-4 sm:mb-8" />
                    <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4 sm:mb-6 leading-tight">
                        Just-in-Time Fact-Checking: Protect Truth in Real Time
                    </h1>
                    <p className="text-lg sm:text-xl mb-8 sm:mb-12">
                        Together, we can verify information instantly and build a community based on trust and facts.
                    </p>
                    <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-6">
                        <button
                            onClick={() => setShowWaitlist(true)}
                            className="bg-[#FFD700] text-black px-6 sm:px-8 py-3 sm:py-4 rounded-lg sm:rounded-full text-base sm:text-lg font-semibold hover:bg-yellow-400 transition-colors"
                        >
                            Join Waitlist
                        </button>
                        <button
                            onClick={() => setShowSignup(true)}
                            className="bg-[#FFD700] text-black px-6 sm:px-8 py-3 sm:py-4 rounded-lg sm:rounded-full text-base sm:text-lg font-semibold hover:bg-yellow-400 transition-colors"
                        >
                            Sign Up
                        </button>
                    </div>
                </div>
            </div>

            {showWaitlist && (
                <Modal onClose={() => setShowWaitlist(false)}>
                    <h3 className="text-2xl font-semibold mb-6 text-gray-800">Join our Waitlist</h3>
                    <form onSubmit={handleWaitlistSubmit}>
                        <div className="mb-4">
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition-all"
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition-all"
                            />
                        </div>
                        <div className="mb-4">
                            <select
                                name="state"
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition-all"
                            >
                                <option value="">Select State</option>
                                {usStates.map(state => (
                                    <option key={state} value={state}>{state}</option>
                                ))}
                            </select>
                        </div>
                        {waitlistError && (
                            <div className="mb-4 p-2 bg-red-50 text-red-600 rounded-lg text-sm">
                                {waitlistError}
                            </div>
                        )}
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={submitButtonClass}
                        >
                            {isLoading ? (
                                <span className="flex items-center justify-center">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Processing...
                                </span>
                            ) : 'Join Waitlist'}
                        </button>
                    </form>
                </Modal>
            )}

            {showSignup && (
                <Modal onClose={() => setShowSignup(false)}>
                    <h3 className="text-2xl font-semibold mb-6 text-gray-800">Sign Up</h3>
                    <form onSubmit={handleSignupSubmit}>
                        <div className="mb-4">
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition-all"
                            />
                            {signupErrors.name && (
                                <p className="mt-1 text-sm text-red-600">{signupErrors.name}</p>
                            )}
                        </div>
                        <div className="mb-4">
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition-all"
                            />
                            {signupErrors.email && (
                                <p className="mt-1 text-sm text-red-600">{signupErrors.email}</p>
                            )}
                        </div>
                        <div className="mb-4">
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition-all"
                            />
                            {signupErrors.password && (
                                <p className="mt-1 text-sm text-red-600">{signupErrors.password}</p>
                            )}
                        </div>
                        <div className="mb-4">
                            <select
                                name="state"
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition-all"
                            >
                                <option value="">Select State</option>
                                {usStates.map(state => (
                                    <option key={state} value={state}>{state}</option>
                                ))}
                            </select>
                            {signupErrors.state && (
                                <p className="mt-1 text-sm text-red-600">{signupErrors.state}</p>
                            )}
                        </div>
                        {signupErrors.general && (
                            <div className="mb-4 p-2 bg-red-50 text-red-600 rounded-lg text-sm">
                                {signupErrors.general}
                            </div>
                        )}
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={submitButtonClass}
                        >
                            {isLoading ? (
                                <span className="flex items-center justify-center">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Processing...
                                </span>
                            ) : 'Sign Up'}
                        </button>
                    </form>
                </Modal>
            )}
        </div>
    );
};

export default EnhancedForms;